import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import css from "../css/tv_favorites.module.scss"
import { webTitle } from "../components/settings"

const TvFavorites = () => {
  useEffect(() => {
    document.title = `Избранное / ${webTitle}`
  }, [])

  const list = [
    "8124", // один дома
    "178707", // сверхнатуралы
    "252089", // доктор кто
    "681656", // последний корабль
    "178710", // доктор хаус
    "571335", // бесстыжие
    "253245", // офис
    "1200189", // засланец из космоса
    "1176120", // битые пиксели
    "1236393", // голяк
    "258687", // интерстеллар
    "476", // назад в будущее
    "1046206", // бумажный дом
    "1301710", // игра в кальмара
    "855898", // Эш Против Зловещих Мертвецов
    "427198", // Социальная Сеть
    "507817", // Третий Лишний
    "64187", // Пила: Игра На Выживание
    "61237", // Железный Человек
    "14349", // Такси
    "666", // Форсаж
    "838", // Человек-паук
    "1309174", // Уокер
    "582359", // В Поле Зрения
    "1040428", // Сквозь Снег
    "1203039", // Локи
    "1091", // Люди В Черном
    "474700", // Бывает И Хуже
    "255370", // Малкольм В Центре Внимания
    "5932", // Очень Страшное Кино
    "801", // Обитель Зла
    "689", // Гарри Поттер И Философский Камень
    "77046", // Секретные Материалы
    "694406", // Закон Каменных Джунглей
    "1046704", // Алекс Райдер
    "1367856", // Мы Все Мертвы
    "843831", // Сноуден
    "81288", // Трансформеры
    "771194", // Halo
    "4476885", // Извне
    "1134778", // Платформа
    "251733", // Аватар
    "1392743", // Постучись В Мою Дверь
    "823594", // Вечность
    "1320895", // Периферийные Устройства
    "1355296", // Ла-брея
    "795260", // 12 обезьян сериал
    "428930", // Обмани меня
    "1142153", // Новичок
    "4412019", // буровая
    "1074910", // Мой ужасный сосед
    "574704", // под куполом
    "104122", // Остаться в живых
    "575613", // Бегущий в лабиринте
    "258048", // Побег
    "585595", // Однажды в сказке
    "766149", // Сосны
    "4541", // Шоу Трумана
    "277537", // Декстер
    "461353", // Проповедник
    "775276", // Зверополис
    "427122", // Zомбилэнд
    "276268", // Потерянная комната
    "958578", // Человек будущего
    "94222", // Квантовый скачок
    "1045553", // Озарк
    "466844", // Морской бой
    "484623", // Таможня дает добро
    "4968949", // Захваченный рейс
    "736182", // Убийство на пляже
    "396481", // Волшебники из Вэйверли Плэйс
    "386715", // Компьютерщики
    "460586", // Пацаны
    "1431133", // Поколение «Ви»
    "962012", // Ривердэйл
    "1171911", // Видеть
    "1127489", // Загрузка
    "4946926", // 57 секунд
    "77720", // Идиократия
    "824275", // Я — зомби
    "5247", // Виртуозность
    "582314", // Гримм
    "915196", // Очень странные дела
    "195523", // Мир Дикого Запада
    "426356", // Флэш Гордон
    "1044571", // Кровавая езда
    "4693201", // Задача трёх тел
    "1343318", // Разделение
    "5024113", // Тёмная материя
    "998777", // Путешественники
    "487409", // Элизиум: Рай не на Земле
    "2656" // Пятый элемент
  ]

  return (
    <>
      <section className={css.main}>
        <header>
          <Link to="/tv">TV</Link>
        </header>

        <main>
          <div className={css.title}>
            <h3>Избранных фильмов: {list.length}</h3>
          </div>
          <div className={css.items}>
            {list.map((v, i) => {
              return (
                <a
                  className={css.item}
                  href={`${window.location.origin}/tv/watch?id=${v}`}
                  key={i}
                >
                  <img
                    src={`https://kinopoiskapiunofficial.tech/images/posters/kp_small/${v}.jpg`}
                    alt={v}
                    loading="lazy"
                  />
                </a>
              )
            })}
          </div>
        </main>
      </section>
    </>
  )
}

export default TvFavorites

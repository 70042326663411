import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import css from "../css/tv_collections.module.scss"
import { webTitle } from "../components/settings"

const Collections = () => {
  useEffect(() => {
    document.title = `Коллекции / ${webTitle}`
  }, [])

  // https://kinopoiskapiunofficial.tech/images/posters/kp_small/${id}.jpg
  // x1000 //st.kp.yandex.net/images/film_big/8124.jpg
  // x360 //st.kp.yandex.net/images/film_iphone/iphone360_8124.jpg

  const list = {
    ["Один дома"]: ["8124", "5928"],
    ["Гарри Поттер"]: [
      "689",
      "688",
      "322",
      "8408",
      "48356",
      "89515",
      "276762",
      "407636",
    ],
    ["Назад в будущее"]: ["476", "5502", "7103"],
    ["Третий лишний"]: ["507817", "731691", "4475348"],
    ["Пила"]: [
      "64187",
      "86206",
      "184417",
      "271728",
      "400094",
      "414547",
      "470156",
      "810679",
      "1266725",
      "5080630",
    ],
    ["Железный человек"]: ["61237", "411924", "462762"],
    ["Такси"]: ["14349", "854", "14339", "258478"],
    ["Форсаж"]: [
      "666",
      "323",
      "106079",
      "395978",
      "496943",
      "594736",
      "754481",
      "894027",
      "964318",
      "959062",
    ],
    ["Человек-паук"]: ["838", "2898", "82441", "278217", "602409", "1309570"],
    ["Стюарт Литтл"]: ["5327", "5326", "229520", "172334"],
    ["Шрэк"]: ["430", "5273", "84020", "271806"],
    ["Парк Юрского периода"]: [
      "7121",
      "1060",
      "2982",
      "594554",
      "924311",
      "1115098",
    ],
    ["Матрица"]: ["301", "299", "316"],
    ["Ледниковый период"]: [
      "707",
      "86204",
      "392930",
      "522180",
      "818145",
      "4296550",
    ],
    ["Звездный путь"]: [
      "229272",
      "229271",
      "6528",
      "6583",
      "6582",
      "6527",
      "229996",
      "6580",
      "6460",
      "229226",
      "3479",
      "229263",
      "3475",
      "3470",
      "161238",
      "321",
      "255129",
      "455165",
      "734349",
      "977754",
      "1178022",
      "1209195",
      "1247146",
      "1379545",
      "925994",
    ],
    ["Трансформеры"]: [
      "81288",
      "373391",
      "452899",
      "651857",
      "811609",
      "952241",
      "937438",
    ],
    ["Книга джунглей"]: ["8133", "5577", "779024"],
    ["Люди в чёрном"]: ["1091", "6379", "455773", "693730"],
    ["Терминатор"]: ["507", "444", "319", "160932", "436225", "1025082"],
    ["Люди Икс"]: [
      "886",
      "298",
      "61361",
      "462358",
      "462754",
      "597687",
      "814016",
      "807682",
      "1012431",
      "910085",
    ],
    ["Очень страшное кино"]: ["5932", "5931", "5002", "32655", "255379"],
    ["Обитель зла"]: ["801", "24186", "88172", "409208", "581493", "726753"],
    ["Аватар"]: ["251733", "505898", "570402", "783486", "973064"],
    ["Бегущий в лабиринте"]: ["575613", "842673", "896631"],
    ["Добро пожаловать в Zомбилэнд"]: ["427122", "744257", "489414"],
    ["🇷🇺"]: [
      "431219",
      "471027",
      "502845",
      "474787",
      "569993",
      "627273",
      "756251",
      "797028",
      "594828",
      "679817",
      "794329",
      "840013",
      "675488",
      "794742",
      "841314",
      "1309198",
    ],
    ["Disney"]: [
      "2360",
      "77038",
      "77040",
      "842208",
      "5277",
      "84356",
      "2361",
      "88628",
      "229178",
      "88706",
      "540",
      "229366",
      "229365",
      "8147",
      "229458",
      "229358",
      "308804",
      "740",
      "77260",
      "230374",
      "160921",
      "251609",
      "8129",
      "79428",
      "597",
      "88747",
      "2357",
      "229152",
      "229171",
      "8227",
      "229318",
      "658",
      "229782",
      "8225",
      "679486",
      "77548",
      "669880",
      "8222",
      "458",
      "468904",
      "1171899",
      "775273",
      "4432435",
      "1100646",
      "775278",
      "837530",
      "1015471",
      "325381",
      "279102",
      "89514",
      "432724",
      "743090",
      "550910",
      "694051",
      "820599",
      "1009513",
      "61249",
      "490462",
      "409013",
      "839646",
      "1370469",
      "482",
      "405",
      "258328",
      "581104",
      "612481",
      "696048",
      "712551",
      "820821",
      "846824",
      "1322610",
      "586260",
      "988782",
      "7908",
      "692957",
      "645118",
      "929237",
      "5102255",
      "70955",
      "453255",
      "775276",
      "70953",
      "8154",
      "229854",
    ],
  }

  return (
    <>
      <section className={css.main}>
        <header>
          <Link to="/tv">TV</Link>
        </header>

        {Object.keys(list).map((v, i) => {
          return (
            <div className={css.collection} key={i}>
              <div className={css.title}>{v}</div>
              <div className={css.items}>
                {list[v].map((v, i) => {
                  return (
                    <div className={css.item} key={i}>
                      <Link to={`/tv/watch?id=${v}`}>
                        <img
                          src={`//st.kp.yandex.net/images/film_big/${v}.jpg`}
                          alt=""
                        />
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </section>
    </>
  )
}

export default Collections
